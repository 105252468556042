<template>
  <div class="generic-list scroll-container spesa-self">
    <CategoryTitle :category="category" />

    <div
      v-if="melaleggoActive"
      class="primary--text white darken-1 rounded-sm mt-6 py-2 px-6 d-flex justify-space-between"
    >
      <h3 class="d-flex align-center">
        <strong> {{ $t("dashboard.melaleggoActive") }} </strong>
      </h3>
      <v-switch
        color="success"
        readonly
        :input-value="melaleggoActive"
      ></v-switch>
    </div>
  </div>
</template>
<style lang="scss">
.spesa-self {
  .hide-description {
    div:nth-child(2) {
      display: none;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";
import { mapGetters } from "vuex";

export default {
  name: "SpesaSelf",
  data() {
    return {
      promovar: "promovar_1100",
      melaleggoActive: false
    };
  },
  mixins: [categoryMixin, reload],
  components: {
    CategoryTitle
  },
  computed: {
    ...mapGetters({ user: "cart/getUser" })
  },
  mounted() {
    let crm = this.user.crmUserSegments.find(
      e => e.crmSegment.code == this.promovar
    );
    if (crm && crm.value && crm.value == 1) {
      this.melaleggoActive = true;
    }
  }
};
</script>
